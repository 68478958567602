import * as appData from "../src/appData"; 
import * as storage from "../src/storage"; 


const checkArray = function ( aryObj ) {
    let _i = 0,
        _a,
        _len = aryObj.length,
        totalMatchers = arguments.length - 1,
        matcher,
        target;

    while ( _i < _len ) {
        //check if aryObj is single or multidimensional array
        target = Array.isArray( aryObj[ _i ] ) ? aryObj[ _i ][ 0 ] : aryObj[ _i ];
        //if multi arguments loop through test
        for ( _a = 1; _a < totalMatchers + 1; _a++ ) {
            matcher = arguments[ _a ];
            if ( matcher.indexOf( target ) > -1 ) {
                return ( Array.isArray( aryObj[ _i ] ) && ( aryObj[ _i ].length > 1 ) ? aryObj[ _i ][ 1 ] : aryObj[ _i ] );
            }
        }
        _i++;
    }
};


/**
 * List values (array) within a given object, no matter level of JSON complexity
 * @param objData
 * @param out - can pass in an empty array or nothing at all if no matchKey
 * @param matchKey optional string value, if found, will return all values of that key found
 * @returns {*|Array}
 * examples:
 *   getAllValues(digitalData.transaction,[],'sku')
 *       > this returns all values of keys named "sku" in the transaction node of digitalData
 *   getAllValues(digitalData)
 *       > this returns all values of all keys in digitalData object (used to see if any have values assigned (digitalData in use)
 */
const getAllValues = function ( objData, out = [], matchKey = '') {

	let keyVal;

    for ( let key in objData ) {
        keyVal = objData[ key ];

        let nodetype = typeof ( objData[ key ] );
        if ( nodetype === 'object' || nodetype === 'array' ) {
            out = getAllValues( objData[ key ], out, matchKey );
        }
        else {
            if ( keyVal !== undefined && typeof ( keyVal ) !== "function" ) {
                if ( matchKey === '' || matchKey && matchKey === key ) {
                    out.push( keyVal );
                }
            }
        }
    }
    return ( out );
};

/**
 * Evaluate object for any defined values
 * @param objToInspect
 * @returns {boolean} Does object have any defined values
 */
const objHasValue = function ( objToInspect ) {
    let arrAllValues = getAllValues( objToInspect );
    return arrAllValues.length > 0;
};

/**
 * Window Orientation for prop 34
 * @param:
 * @returns:
 */
const getWindowOrientation = function () {
    let theOrientation = "";

	/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
    try {
        if ( window.innerWidth > window.innerHeight ) {
            theOrientation = "landscape";
        }
        else {
            theOrientation = "portrait";
        }
    }
    catch ( e ) {}

	return theOrientation;

};


const getValue =  ( obj, path ) => path.split('.').reduce((acc, c) => acc && acc[c], obj);

const getValuesByPath = ( obj, path ) => {
    
    //exit early if params not correct
    if ( typeof( obj ) === 'undefined' || typeof( path ) !== 'string') return [];

    let temp = [], result= [];
    
    if ( !Array.isArray( obj ) ){
      temp.push( obj ) ;  
      obj = temp;  
    }  

    for ( let i = 0; i < obj.length; i++ ) {        
        let val = getValue( obj[i], path );
        if (val) result.push( val );
    }

    return result;
};


const addEventsToLinkTrack = function( list ) {

	let tempListToArray = list.split( "," );

	tempListToArray.forEach( item => _aape.metrics.linkTrackEvents.add( item ) );

	console.log( LOG_PREFIX + " -- _aape.metrics.linkTrackEvents: ",  _aape.metrics.linkTrackEvents );

};


const addVarsToLinkTrack = function( list ) {

	let tempListToArray = list.split( "," );

	tempListToArray.forEach( item => _aape.metrics.linkTrackVars.add( item ) );

	console.log( LOG_PREFIX + " -- _aape.metrics.linkTrackVars: ",  _aape.metrics.linkTrackVars );

};

let redactEmail = function( item ) {
    if ( item.search(/[a-zA-Z0-9._%+]{1,64}@([a-zA-Z0-9]{1,63}\.){1,2}[a-zA-Z][a-zA-Z0-9]{1,62}/) > -1 ) {
		return "email address";
	} else {
		return item;
	}
};


/* eslint-disable */
/**
 * Lower case function to ensure that everything in S object that can be lower cased before being sent.
 */
var lowercaseAndCleanse = function () {

	/* Stop loop at 243 since, eVar244 Neustar fabrick id, eVar245 LP session ID, eVar249 (ECR Party ID) and eVar250 (google id) need to be passed as is */
	for ( var a = 1; a <= 243; a++ ) {

		// Props
		if ( _aape.metrics[ "prop" + a ] && typeof ( _aape.metrics[ "prop" + a ] ) === 'string' ) {
			_aape.metrics[ "prop" + a ] = redactEmail( _aape.metrics[ "prop" + a ].toLowerCase().replace( /evar/, 'eVar' ).replace( /=pagename/, '=pageName' ) );
		} 

		// Evars
		if (_aape.metrics[ "eVar" + a ] && typeof ( _aape.metrics[ "eVar" + a ] ) === 'string' ) {
			if ( a !== 183 ) {
				_aape.metrics[ "eVar" + a ] = _aape.metrics[ "eVar" + a ].toLowerCase().replace( /evar/, 'eVar' ).replace( /=pagename/, '=pageName' );
			}
			_aape.metrics[ "eVar" + a ] = redactEmail( _aape.metrics[ "eVar" + a ] );
		}

		if ( a <= 5 && _aape.metrics[ "hier" + a ] && typeof ( _aape.metrics[ "hier" + a ] ) === 'string' ) {
			_aape.metrics[ "hier" + a ] = redactEmail( _aape.metrics[ "hier" + a ].toLowerCase().replace( /evar/, 'eVar' ).replace( /=pagename/, '=pageName' ) );
		}

	}

	for ( var b = [ "products", "pageName", "channel", "campaign", "state", "purchaseID", "transactionID" ], a = 0; a < b.length; a++ ) {
	
		if( _aape.metrics[ b[ a ] ] && typeof ( _aape.metrics[ b[ a ] ] ) === 'string' ) {
			_aape.metrics[ b[ a ] ] = redactEmail( _aape.metrics[ b[ a ] ].toLowerCase().replace( /evar/g, 'eVar' ).replace( /=pagename/, '=pageName' ) );
		}

	}

};
/* eslint-enable */


/**
 * For appending event metrics: similar to Adobe's s.apl, but waaay better.
 * @param {string} oldList Reference to _aape.metrics.events (or test string)
 * @param {string} newList Comma Delimited string of events to add (can be number only: '15' instead of 'event15')
 * @returns {string|*}
 */
const apl = function ( newList, oldList ) {
    let inEvents = [],
        outEvents = [],
        i = 0,
        mergeList;
    oldList = typeof oldList !== "undefined" ? oldList : _aape.metrics.events ? _aape.metrics.events : '';
    //if multiple values, split to array and add "event" before numbers
    inEvents = newList.split( ',' );
    for ( i; i < inEvents.length; i++ ) {
        //if setParam IS a number then prepend with "event"
        if ( inEvents[ i ] && isNaN( inEvents[ i ] ) === false ) {
            inEvents[ i ] = "event" + inEvents[ i ];
        }
        //If value is not already in _aape.metrics.events then push into output
        ////adding a comma to end list to assure matches (so all end with comma)
        if ( ( oldList + ',' ).indexOf( inEvents[ i ] + ',' ) === -1 ) {
            outEvents.push( inEvents[ i ] );
        }
    }
    mergeList = outEvents.toString();
    if ( oldList ) {
        if ( mergeList ) {
            mergeList = oldList + ',' + mergeList;
        }
        else {
            mergeList = oldList;
        }
    }
    return mergeList;
};

/**
 * Removes all occurrences of an item from a given list
 * @param {string} list The list to remove the item from
 * @param {string} value The value to remove from the list
 * @returns {string|*} List without any occurrences of the given item
 */
const listRemove = function ( list, value, delim = "," ) {

    list = ( typeof ( list ) === 'string' ? list : '' );
    
    const listAsArray = list.split( delim );

    const result = listAsArray.filter(item => item !== value);
    
    return result.join( delim );
  }


/**
 *
 * @param:
 * @returns:
 */
const validateDataObj = function ( dObj ) {

    dObj.page = dObj.page ? dObj.page : {};
    dObj.page.myAccount = dObj.page.myAccount ? dObj.page.myAccount : {};
    dObj.page.pageInfo = dObj.page.pageInfo ? dObj.page.pageInfo : {};
    dObj.page.pageInfo.pageName = dObj.page.pageInfo.pageName ? dObj.page.pageInfo.pageName : "";
    dObj.page.pageInfo.pageType = dObj.page.pageInfo.pageType ? dObj.page.pageInfo.pageType : "";
    dObj.page.category = dObj.page.category ? dObj.page.category : {};
    dObj.page.onsiteSearchInfo = dObj.page.onsiteSearchInfo ? dObj.page.onsiteSearchInfo : {};
    dObj.page.myList = dObj.page.myList ? dObj.page.myList : {};
    dObj.site = dObj.site ? dObj.site : {};
    dObj.site.businessType = dObj.site.businessType ? dObj.site.businessType : "";
    dObj.site.experienceType = dObj.site.experienceType ? dObj.site.experienceType : "";
    dObj.site.siteVersion = dObj.site.siteVersion ? dObj.site.siteVersion : "";
    dObj.user = dObj.user ? dObj.user : {};
    dObj.user.localization = dObj.user.localization ? dObj.user.localization : {};
    dObj.user.profile = dObj.user.profile ? dObj.user.profile : {};
    dObj.user.profile.profileInfo = dObj.user.profile.profileInfo ? dObj.user.profile.profileInfo : {};
    dObj.user.b2bProfile = dObj.user.b2bProfile ? dObj.user.b2bProfile : {};
    dObj.user.b2bProfile.perks = dObj.user.b2bProfile.perks ? dObj.user.b2bProfile.perks : {};
    dObj.user.b2bProfile.preferredPricing = dObj.user.b2bProfile.preferredPricing ? dObj.user.b2bProfile.preferredPricing : {};

    dObj.cart = dObj.cart ? dObj.cart : {};
    dObj.cart.price = dObj.cart.price ? dObj.cart.price : {};
    dObj.transaction = dObj.transaction ? dObj.transaction : {};
    dObj.transaction.profile = dObj.transaction.profile ? dObj.transaction.profile : {};
    dObj.transaction.profile.address = dObj.transaction.profile.address ? dObj.transaction.profile.address : {};
    dObj.transaction.price = dObj.transaction.price ? dObj.transaction.price : {};
    dObj.product = dObj.product ? dObj.product : [];
    dObj.postOrder = dObj.postOrder ? dObj.postOrder : {};

    return dObj;

};

const isDuplicate = function ( pageName = '' ) {

    let result = false;
    let lastEntry = '';

    if ( pageName !== null ) {

        _aape.PUB_SUB.broadcastArchive.length && _aape.PUB_SUB.broadcastArchive.forEach( function ( broadcast ) {
                        
            if ( broadcast.name === "thdcoreanalytics|beacon" && broadcast.data && ( broadcast.data.beaconType === 't' && broadcast.data.trigger.indexOf('overlay*') === -1 ) ) {
                lastEntry = ( broadcast.data.pageName ? broadcast.data.pageName : '' );
            }

        } );

        if ( lastEntry ) {

            result = ( pageName.toString().toLowerCase() === lastEntry.toString().toLowerCase() );

        }

    }
    
	return result;

};

const getUUID = function () {

    let hex = [];

    for ( let i = 0; i < 256; i++ ) {
        hex[ i ] = ( i < 16 ? '0' : '' ) + ( i ).toString( 16 );
    }

    function makeUUID() {
        let r = crypto.getRandomValues( new Uint8Array( 16 ) );

        /* jshint bitwise: false */
        r[ 6 ] = r[ 6 ] & 0x0f | 0x40;
        r[ 8 ] = r[ 8 ] & 0x3f | 0x80;
        /* jshint bitwise: true */

        return (
            hex[ r[ 0 ] ] +
            hex[ r[ 1 ] ] +
            hex[ r[ 2 ] ] +
            hex[ r[ 3 ] ] +
            "-" +
            hex[ r[ 4 ] ] +
            hex[ r[ 5 ] ] +
            "-" +
            hex[ r[ 6 ] ] +
            hex[ r[ 7 ] ] +
            "-" +
            hex[ r[ 8 ] ] +
            hex[ r[ 9 ] ] +
            "-" +
            hex[ r[ 10 ] ] +
            hex[ r[ 11 ] ] +
            hex[ r[ 12 ] ] +
            hex[ r[ 13 ] ] +
            hex[ r[ 14 ] ] +
            hex[ r[ 15 ] ]
        );
    }

    if ( ( typeof crypto !== "undefined" ) && ( typeof crypto.getRandomValues === "function" || typeof crypto.getRandomValues === "object" ) ) {

        return makeUUID();

    }
    else {

        return Math.floor( Math.random() * Math.floor( 10000000000000000000 ) );

    }

};

const snapshotInstanceOfDigitalData = async function( digitalData ) {

    let instanceID = getUUID();
    let validatedDDO = validateDataObj( digitalData );
    let ddoInstance = {};

    try {

		let clone = await import( /* webpackChunkName: "lodash", webpackPrefetch: true */ 'lodash-es/cloneDeep' );

		let cloneDeep = clone.default;

        // console.log( LOG_PREFIX + " -- Lodash is loaded: ", clone );

        ddoInstance = cloneDeep( validatedDDO );
        ddoInstance.instanceIsDeepClone = true;
        ddoInstance.instanceID = instanceID;
        appData.addUpdateDDOInstance( instanceID, ddoInstance );

        return {
            "id": instanceID,
            "ddo": ddoInstance
        };

    } catch ( error ) {

        console.warn( "AAPE LOG -- Unable to Create Deep Clone of digitalData" );
        console.error( `AAPE LOG -- Error: ${ error }` );

        ddoInstance = validatedDDO;
        ddoInstance.instanceIsDeepClone = false;
        ddoInstance.instanceID = instanceID;
        appData.addUpdateDDOInstance( instanceID, ddoInstance );

        return {
            "id": instanceID,
            "ddo": ddoInstance
        };
    }

};

const setDimension = ( object, path, value, lcase = true ) => {

	const nodes = path.split( '.' );
	const last = nodes.length - 1;

	for ( let i = 0; i < last; ++i ) {
		const key = nodes[ i ];

		/* The nullish coalescing (??) operator is a logical 
		// operator that returns its right-hand side operand 
		// when its left-hand side operand is null or undefined, 
		// and otherwise returns its left-hand side operand. */
		object = object[ key ] ?? ( object[ key ] = {} );
	}

	const key = nodes[ last ];
	object[ key ] = ( lcase && typeof ( value ) !== 'undefined' && value !== null ? value.toString().toLowerCase() : value );
};


// Formerly Adobe Consulting Plugin
// It has been rewritten here; though, it still follows the same gerenal philosophy.
const clearVars = function() {

	Object.entries( window._aape.metrics ).forEach( ( [ key ] ) => {

		// console.log( `Before ${ key } -- ${ value }` ); 

		if ( [ "prop", "eVar", "hier", "list" ].indexOf( key.substring( 0, 4 ) ) > -1 || [ "channel", "events", "eventList", "products", "productList", "purchaseID", "transactionID", "state", "zip", "campaign" ].indexOf( key ) > -1 ) {
			window._aape.metrics[ key ] = "";
		}

		_aape.metrics.linkTrackVars.clear();
		_aape.metrics.linkTrackEvents.clear();

		// console.log( `After ${ key } -- ${ window._aape.metrics[ key ] }` );

	} );

}


// Formerly Adobe Plugin
// Modified to utilize our storage functions module
const getDaysSinceLastVisit = function() {
	let now = new Date,
		nowTime = now.getTime(),
		lastVisit = storage.getItem( "s_dslv" );

	console.log( LOG_PREFIX + " -- getDaysSinceLastVisit -- lastVisit: ", lastVisit );

	// Default is a three year lifespan in millisecons.
	// 31,556,952,000 ms = 1 year
	// Our local storage implementation takes an optional lifespan argument.
	storage.setItem( "s_dslv", nowTime, 94670856E3 );

	if ( lastVisit ) {

		let diff = nowTime - lastVisit;

		if ( 18E5 < diff ) { // 30 minutes
			if ( 31536E6 < diff ) return "More than a year";
			if ( 2592E6 < diff ) return "More than 30 days";
			if ( diff < 2592E6 + 1 && 6048E5 < diff ) return "More than 7 days";
			if ( diff < 6048E5 + 1 && 864E5 < diff ) return "Less than 7 days";
			if ( diff < 864E5 + 1 ) return "Less than 1 day"

		}

		return ""

	}
	
	return "New Visitor"
};

// Formerly Adobe Consulting Plugin: getNewRepeat v2.0, which required AppMeasurement
// Modified to utilize our storage functions module and deobfuscated
// This function checks if a certain event is new or a repeat within a given number of days.
const getNewRepeat = function( days = 30 ) {

	// Create a new Date object and get the current time in milliseconds.
	let currentDate = new Date;
	let currentTime = currentDate.getTime();

	// Construct a key for the local storage entry.
	let storageKey = "s_nr" + days;

	// Set the time of the Date object to 'days' days in the future.
	// 864E5 is a way of writing 86400000 using scientific notation. 86400000 milliseconds is the equivalent of one day.
	let lifespan = currentDate.setTime( 864E5 * days );

	// Retrieve the value from local storage.
	let storedValue = storage.getItem( storageKey );

	// If the value does not exist or is an empty string, set the local storage item
	// and return "New".
	if ( storedValue.length === 0 ) {
		storage.setItem( storageKey, currentTime + "-New", lifespan );
		return "New";
	}

	// Split the stored value into a timestamp and a status ("New" or "Repeat").
	storedValue = storedValue.split( "-" );

	// If the current time is less than 5 hours away from the stored timestamp and the status is "New",
	// update the local storage item and return "New".
	if ( 1800000 > currentTime - storedValue[ 0 ] && "New" === storedValue[ 1 ] ) {
		storage.setItem( storageKey, currentTime + "-New", lifespan );
		return "New";
	}

	// If the conditions above are not met, update the local storage item and return "Repeat".
	storage.setItem( storageKey, currentTime + "-Repeat", lifespan );
	return "Repeat";

};

export {
	addEventsToLinkTrack,
	addVarsToLinkTrack,
	apl,
	checkArray,
	clearVars,
	getDaysSinceLastVisit,
	getNewRepeat,
	getUUID,
	getValuesByPath,
	getWindowOrientation,
	isDuplicate,
	listRemove,
	lowercaseAndCleanse,
	objHasValue,
	setDimension,
	snapshotInstanceOfDigitalData,
	validateDataObj
};
